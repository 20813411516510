// import moment from "moment";
import React, { Component } from "react";
import LoadingPage from "../../components/Loading/Loading";
import TableServiceCenter from "../../components/Table/TableServiceCenter";
import config from "../../config";
import utilityFunctions from "../../store/utilityFunctions";
import errorFunctions from "../../store/errorFunctions";
import "../common.css";
import "./ServiceCenterList.css";
// import { IoMdAddCircle } from "react-icons/io";
import { MdClose } from "react-icons/md";
import Modal from "react-modal";
import ServiceCenterAdd from "./ServiceCenterAdd";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Select from "react-select";

const countryOptions = [
  { value: "AU", label: "Australia" },
  { value: "NZ", label: "New Zealand" },
];

export default class ServiceCenterList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      serviceCenters: [],
      serviceCenterCentrals: [],
      serviceCenterCentral: "",
      serviceCenterCentralId: 0,
      serviceCenterCentralName: "",
      serviceCenterName: "",
      searchFilter: "",
      openServiceCenterCentralAdd: false,
      showDeleteModal: false,
      openServiceCenterAdd: false,
      isAdd: false,
    };
  }

  componentDidMount() {
    this.retrieveServiceCenterCentral();
    // this.retrieveServiceCenter(this.props.selectedbrandID);
  }
  // componentDidUpdate(prevProps) {
  //   if (this.props.selectedbrandID !== prevProps.selectedbrandID) {
  //     this.retrieveServiceCenterCentral(this.props.selectedbrandID);
  //   }
  // }
  retrieveServiceCenter = (id) => {
    this.setState({ loading: true });
    this.props
      .protectedFetch(config.backendServer + "service_center_central/" + id + "/", "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        //do not show email with @treeclouds.com and @karadigital.co
        this.setState({ loading: false, serviceCenters: res.service_centers });
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false });
      });
  };

  retrieveServiceCenterCentral = () => {
    this.setState({ loading: true });
    this.props
      .protectedFetch(config.backendServer + "service_center_centrals/", "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        //do not show email with @treeclouds.com and @karadigital.co
        this.setState({ loading: false, serviceCenterCentrals: res, serviceCenterCentral: res[0], serviceCenterCentralId: res[0].id });
        this.retrieveServiceCenter(res[0].id);
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false });
      });
  };

  addServiceCenterCentral = () => {
    this.setState({ loading: true });
    const payload = { name: this.state.serviceCenterCentralName, country: this.state.country };
    this.props
      .protectedFetch(config.backendServer + "service_center_central/", "POST", payload, errorFunctions.checkResponseThenJson)
      .then(() => {
        //do not show email with @treeclouds.com and @karadigital.co
        this.setState({
          loading: false,
          // serviceCenterCentrals: res,
          serviceCenterCentralId: 0,
          serviceCenterCentralName: "",
          openServiceCenterCentralAdd: false,
          isAdd: false,
        });
        this.retrieveServiceCenterCentral();
        // this.retrieveServiceCenter(res[0].id);
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false });
      });
  };
  updateServiceCenterCentral = () => {
    this.setState({ loading: true });
    const payload = { name: this.state.serviceCenterCentralName };
    this.props
      .protectedFetch(
        config.backendServer + "service_center_central/" + this.state.serviceCenterCentralId + "/",
        "PATCH",
        payload,
        errorFunctions.checkResponseThenJson
      )
      .then(() => {
        //do not show email with @treeclouds.com and @karadigital.co
        this.setState({
          loading: false,
          // serviceCenterCentrals: res,
          openServiceCenterCentralAdd: false,
          serviceCenterCentralId: 0,
          isAdd: false,
          // serviceCenterCentralName: "",
        });
        this.retrieveServiceCenterCentral();
        // this.retrieveServiceCenter(res[0].id);
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false });
      });
  };

  deleteServiceCenterCentral = async () => {
    this.setState({ loading: true });
    const userInfo = localStorage.getItem("userInfo") || utilityFunctions.getCookie("userInfo");
    const token = userInfo?.access_token || JSON.parse(userInfo)?.access_token;
    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const url = config.backendServer + "service_center_central/" + this.state.serviceCenterCentralId + "/";
    fetch(url, options)
      .then(async (resp) => {
        if (resp.status === 406) {
          const errorMsg = await resp.json();
          alert(errorMsg.message);
        } else if (resp.status === 204) alert("Delete success");
        //do not show email with @treeclouds.com and @karadigital.co
        this.setState({ loading: false, showDeleteModal: false });
        this.retrieveServiceCenterCentral();
        // this.retrieveServiceCenter(res[0].id);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  filterChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleAddServiceCenter = () => {
    this.setState({ openServiceCenterAdd: true });
  };

  handleEditServiceCenterCentralClick = (sc) => {
    this.setState({ serviceCenterCentralId: sc.id, serviceCenterCentralName: sc.name, openServiceCenterCentralAdd: true, country: sc.country });
    this.retrieveServiceCenter(sc.id);
  };
  handleSellerClick = (id) => {
    this.setState({ serviceCenterCentralId: id });
    this.retrieveServiceCenter(id);
  };

  render() {
    const isMobile = Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") > -1;

    // if (this.state.loading) {
    //   return <LoadingPage />;
    // }
    // let customer = this.state.serviceCenters;

    // Filter out warranties
    let filterServiceCenter = [];

    //RMAS-37 search filter function
    if (this.state.searchFilter) {
      this.state.serviceCenters.forEach((item) => {
        if (
          item.name?.toLowerCase()?.includes(this.state.searchFilter.trim().toLowerCase()) ||
          item.address?.toLowerCase()?.includes(this.state.searchFilter.trim().toLowerCase()) ||
          item.email?.includes(this.state.searchFilter.trim().toLowerCase()) ||
          item.contact_number?.includes(this.state.searchFilter.toString().trim().toLowerCase())
        ) {
          filterServiceCenter.push(item);
        }
      });
    } else {
      this.state.serviceCenters?.map((val) => filterServiceCenter.push(val));
    }

    const tableHead = [
      {
        id: "name",
        label: "Name",
        width: "200px",
        valueDisplay: (value) => value || "",
      },
      // {
      //   id: "address",
      //   label: "Address",

      //   valueDisplay: (value) => value || "",
      // },
      {
        id: "contact_number",
        label: "Phone Number",
        width: "250px",
        valueDisplay: (value) => value || "",
      },
      {
        id: "email",
        label: "Email",
        width: "250px",
        valueDisplay: (value) => value || "email empty",
      },
      {
        id: "tickets",
        label: "Ticket Count",
        valueDisplay: (value) => value || "",
      },
      {
        id: "staff",
        label: "Staff Count",
        valueDisplay: (value) => value || "",
      },

      {
        id: "action",
        label: "Action",
        valueDisplay: (value) => value || "",
      },
    ];

    return (
      <div className={"page"}>
        {this.state.loading && <LoadingPage />}
        <div className="row">
          <div className="d-flex justify-content-between mb-3">
            <div style={{ width: "180px", borderBottom: "1px solid black", textAlign: "center" }}>
              <h5>Service Centers</h5>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <div className="input-group col-3 pr-0">
              <input
                type="text"
                placeholder="Search Service Center"
                id={"searchFilter"}
                className={"form-control"}
                onChange={this.filterChange}
                value={this.state.searchFilter}
                // width={"100rem"}
              />
              <div className="input-group-prepend">
                <span className="form-control ">
                  <i className="fa fa-search form-control-icon"></i>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          {this.state.loading && <LoadingPage />}
          <div className="col-4">
            <div className="row my-2">
              <div className="col-8">Service Center</div>
              <div className="col text-end">
                <button
                  disabled={this.props.userInfo.user.role !== "Admin"}
                  className="btn btn-outline-primary  btn-sm "
                  onClick={() =>
                    this.setState({
                      seller: null,
                      newSeller: null,
                      openServiceCenterCentralAdd: true,
                      showSeller: true,
                      isAdd: true,
                      serviceCenterCentralName: "",
                    })
                  }
                >
                  Add New
                </button>
              </div>
            </div>

            <TableContainer component={Paper}>
              <Table aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 170 }}>Service Center</TableCell>
                    <TableCell style={{ width: 70 }}>Country</TableCell>
                    <TableCell className="text-end">
                      {/* <button
                        className="k-btn k-btn-primary k-btn-sm"
                        onClick={() => this.setState({ seller: null, newSeller: null, showSeller: true })}
                      >
                        Add
                      </button> */}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.serviceCenterCentrals?.map((s, i) => {
                    return (
                      <TableRow key={i} className={this.state.serviceCenterCentralId === s.id ? "font-weight-bold bg-light" : ""}>
                        <TableCell
                          style={{
                            // width: "800px",
                            wordWrap: "break-word",
                            cursor: "pointer",
                          }}
                          onClick={() => this.handleSellerClick(s.id)}
                        >
                          {s.name}
                        </TableCell>
                        <TableCell
                          style={{
                            // width: "800px",
                            wordWrap: "break-word",
                            cursor: "pointer",
                          }}
                          onClick={() => this.handleSellerClick(s.id)}
                        >
                          {s.country}
                        </TableCell>
                        <TableCell
                          style={{
                            width: "120px",
                            wordWrap: "break-word",
                            // alignContent: "end",
                          }}
                        >
                          <div className="flex justify-stretch">
                            <button
                              disabled={this.props.userInfo.user.role !== "Admin"}
                              className={"btn btn-outline-primary btn-sm m-1"}
                              onClick={() => this.handleEditServiceCenterCentralClick(s)}
                            >
                              Edit
                            </button>
                            <button
                              disabled={this.props.userInfo.user.role !== "Admin"}
                              className={"btn btn-outline-danger btn-sm m-1"}
                              onClick={() =>
                                this.setState({
                                  showDeleteModal: true,
                                  serviceCenterCentralName: s.name,
                                  serviceCenterCentralId: s.id,
                                  country: s.country,
                                })
                              }
                            >
                              Delete
                            </button>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className={"pageContent clearfix col-8"}>
            <div className="row my-2">
              <div className="col-10"> Locations</div>
              <div className="col text-end">
                <button
                  disabled={this.props.userInfo.user.role !== "Admin"}
                  title="Add Service Center"
                  className="btn btn-outline-primary btn-sm "
                  onClick={this.handleAddServiceCenter}
                >
                  Add New
                </button>
              </div>
            </div>
            {this.state.serviceCenters.length === 0 && <div className="border p-auto">No Service Center</div>}
            {this.state.serviceCenters.length > 0 && (
              <div className={"admin-table-container card"}>
                <TableServiceCenter
                  head={tableHead}
                  data={filterServiceCenter}
                  handleRetriveServiceCenterList={() => this.retrieveServiceCenter(this.state.serviceCenterCentralId)}
                  protectedFetch={this.props.protectedFetch}
                  serviceCenterCentralId={this.state.serviceCenterCentralId}
                  userInfo={this.props.userInfo}
                />
              </div>
            )}
          </div>
        </div>

        <Modal
          isOpen={this.state.openServiceCenterAdd}
          // onRequestClose={() => this.setState({ openServiceCenterAdd: false })}
          contentLabel={"Add New Device."}
          // style={customStyles}
          className={isMobile ? "input-modal-mobi" : "input-modal"}
        >
          <button onClick={() => this.setState({ openServiceCenterAdd: false })} className={"info-modal-close"}>
            <MdClose />
          </button>

          <ServiceCenterAdd
            protectedFetch={this.props.protectedFetch}
            handleCloseModal={() => this.setState({ openServiceCenterAdd: false })}
            handleRetriveServiceCenterList={() => this.retrieveServiceCenter(this.state.serviceCenterCentralId)}
            serviceCenterCentralId={this.state.serviceCenterCentralId}
            serviceCenterId={this.state.serviceCenterId}
          />
        </Modal>

        <Modal
          isOpen={this.state.openServiceCenterCentralAdd}
          // onRequestClose={() => this.setState({ openServiceCenterAdd: false })}
          contentLabel={"Add New Service Center."}
          // style={customStyles}
          className={isMobile ? "input-modal-mobi" : "input-modal"}
        >
          <button onClick={() => this.setState({ openServiceCenterCentralAdd: false })} className={"info-modal-close"}>
            <MdClose />
          </button>
          <div className="card">
            <div className="card-header">Service Center</div>
            <div className="card-body">
              <div className="mb-3">
                <label className={"single-warranty-details-label"}>Name*</label>
                <input
                  type="text"
                  id="serviceCenterCentralName"
                  //  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                  className="form-control"
                  onChange={this.handleChange}
                  required
                  value={this.state.serviceCenterCentralName}
                />
              </div>
              <div className="mb-3">
                <label className={"single-warranty-details-label"}>Country</label>
                <Select
                  id={"deviceId"}
                  data-testid="assign-to-select"
                  value={countryOptions.find((x) => x.value === this.state.country)}
                  options={countryOptions}
                  onChange={(obj) => {
                    this.setState({ country: obj.value });
                  }}
                />{" "}
              </div>
            </div>
            <div className="card-footer">
              <button
                className="k-btn k-btn-outline-primary mr-3"
                onClick={() => {
                  // this.setState({ openServiceCenterCentralAdd: false });
                  if (this.state.isAdd) {
                    this.addServiceCenterCentral();
                  } else this.updateServiceCenterCentral();
                }}
              >
                Save
              </button>
              <button className="k-btn k-btn-outline-primary" onClick={() => this.setState({ openServiceCenterCentralAdd: false })}>
                Close
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showDeleteModal}
          // onRequestClose={() => this.setState({ openServiceCenterAdd: false })}
          contentLabel={"Add New Service Center."}
          // style={customStyles}
          className={isMobile ? "input-modal-mobi" : "input-modal"}
        >
          <button onClick={() => this.setState({ showDeleteModal: false })} className={"info-modal-close"}>
            <MdClose />
          </button>
          <div className="card">
            <div className="card-header">Service Center</div>
            <div className="card-body">
              <div className="mb-3">
                <label className={"single-warranty-details-label"}>Do you realy want to delete {this.state.serviceCenterCentralName}</label>
              </div>
            </div>
            <div className="card-footer">
              <button
                className="k-btn k-btn-outline-primary mr-3"
                onClick={() => {
                  // this.setState({ openServiceCenterCentralAdd: false });
                  this.deleteServiceCenterCentral();
                }}
              >
                Delete
              </button>
              <button className="k-btn k-btn-outline-primary" onClick={() => this.setState({ showDeleteModal: false })}>
                Close
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
