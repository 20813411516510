import React, { Component } from "react";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import Login from "./pages/AuthenticationPages/Login";
import "./App.css";
import config from "./config";
import AuthContainer from "./pages/AuthenticationPages/AuthContainer";
import NotFound from "./pages/NotFound/NotFound";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import utilityFunctions from "./store/utilityFunctions";
import LoadingPage from "./components/Loading/Loading";
import PageWithNavbar from "./pageContainers/PageWithNavbar/PageWithNavbar";
import Warranties from "./pages/Warranties/Warranties";
import Inventory from "./pages/Inventory/Inventory";
import Reports from "./pages/Reports/Reports";
import SingleWarranty from "./pages/Warranties/SingleWarranty/SingleWarranty";
import Dashboard from "./pages/Dashboard/Dashboard";
import ServiceCenterList from "./pages/ServiceCenter/ServiceCenterList";
import ServiceCenterDetail from "./pages/ServiceCenter/ServiceCenterDetail";
import CustomerList from "./pages/CustomerList/CustomerList";
import CustomerDetail from "./pages/CustomerDetail/CustomerDetail";
import AddNewDevice from "./pages/CustomerDetail/Devices/AddNewDevice/AddNewDevice";
import ManageStaff from "./pages/ManageStaff/ManageStaff";
import StaffDetail from "./pages/ManageStaff/StaffDetail/StaffDetail";
import StaffAdd from "./pages/ManageStaff/StaffAdd/StaffAdd";
import Users from "./pages/Users/Users";
import Device from "./pages/Device/Device";
import Sellers from "./pages/Sellers/Sellers";
import DeviceDetail from "./pages/Device/DeviceDetail/DeviceDetail";
import SingleUser from "./pages/Users/SingleUser/SingleUser";
import errorFunctions from "./store/errorFunctions";

export default class App extends Component {
  constructor(props) {
    super(props);
    const user = localStorage.getItem("userInfo") || utilityFunctions.getCookie("userInfo");
    this.state = {
      isLoading: true,
      isVerified: false, // Set to true if account is verified (in authentication check)
      isSuper: false, // On load, backend says if account is super admin or not
      userInfo: user && JSON.parse(user),
      selectedbrandID: utilityFunctions.getCookie("brandId") || "",
      brands: [],
      employees: null,
    };

    // Check if token exists, and if does set it
  }

  componentDidMount() {
    this.validateUser();
    document.title = config.country === "AU" ? "Dreame Australia - Admin" : "Dreame New Zealand - Admin";
    // this.retrieveEmployeeList();
  }

  validateUser(callback) {
    // Check userInfo and whether it is valid or not
    if (this.state.userInfo) {
      // Need to validate token with server
      const payload = {
        token: this.state.userInfo.access_token,
      };

      this.protectedFetch(config.backendServer + "token/verify/", "POST", payload, errorFunctions.checkResponseThenJson)
        .then((res) => {
          // On resolved and rejected, need to take off loading screen

          this.setState(
            {
              isLoading: false,
              isVerified: true,
              isSuper: !!res.super /* force boolean */,
            },
            () => {
              if (callback) {
                callback();
              }
            }
          );
        })
        .catch(() => {
          this.setState({ isLoading: false }, () => {
            if (callback) {
              callback();
            }
          });
          utilityFunctions.setCookie("userInfo", "", -20); //Setting expires to past should delete cookie
          localStorage.removeItem("userInfo");
        });
      // this.getBrands()
    } else {
      // If not logged in, routes will handle rest
      this.setState({ isLoading: false }, () => {
        if (callback) {
          callback();
        }
      });
      utilityFunctions.setCookie("userInfo", "", -20); //Setting expires to past should delete cookie
      localStorage.removeItem("userInfo");
    }
  }

  userHasAuthenticated = (userInfo, rememberMe, callback) => {
    if (userInfo) {
      this.setState({ userInfo: JSON.parse(userInfo) });
      if (rememberMe) {
        localStorage.setItem("userInfo", userInfo);
      } else {
        utilityFunctions.setCookie("userInfo", userInfo);
      }
    }
    this.validateUser(() => {
      if (callback) {
        callback();
      }
    });
  };
  handleLogout = () => {
    this.userHasAuthenticated(false);
    this.setState({ userInfo: null });
    utilityFunctions.setCookie("userInfo", "", -20); //Setting expires to past should delete cookie
    localStorage.removeItem("userInfo");
    window.location.href = "/login";
  };

  protectedFetch = (url, method, data, checkFunction, formData) => {
    // const userInfo = localStorage.getItem("userInfo") || utilityFunctions.getCookie("userInfo");

    //state jika di refresh akan kembali null, jadi ambil data dari localstorage atau cookies solusinya
    const user = localStorage.getItem("userInfo") || utilityFunctions.getCookie("userInfo");
    const userInfo = user && JSON.parse(user);
    const token = userInfo?.access_token;
    const logout = this.handleLogout;
    return new Promise(function (resolve, reject) {
      let options;
      if (formData) {
        // True / false
        options = {
          method: method,
          headers: {
            Authorization: "Bearer " + token,
            //  "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundaryUh3B7rfCsdu53gsV",
          },
        };
        if (method !== "GET") options.body = data;
      } else {
        options = {
          method: method,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        };
        if (method !== "GET") options.body = JSON.stringify(data || {});
      }
      fetch(url, options)
        .then((response) => {
          // Check if token failure occurred - if so then trigger logout. Else use checkFunction
          if (response.status === 401) {
            reject("token invalid");
            logout();
            return null;
          } else if (response.status === 413) {
            // User is not verified, take to 'Account needs to be verified page'
            reject("not verified");
            return null;
          } else {
            return checkFunction(response);
          }
        })
        .then((response) => {
          if (response) {
            resolve(response);
          } else {
            // window.location.href = "/error";
            reject("check function failed");
          }
        });
      // .catch(() => (window.location.href = "/error"));
    });
  };

  handleSelectedBrandID = (value) => {
    this.setState({ selectedbrandID: value });
    utilityFunctions.setCookie("brandId", value);
  };

  render() {
    const isPtcUser = !!this.state.userInfo?.user?.service_center?.find((s) => s.name?.toLowerCase().includes("ptc"));
    const isAdminSCA = ["Service Center Admin"].includes(this.state.userInfo?.user?.role);
    const isAdminOnly = ["Admin"].includes(this.state.userInfo?.user?.role);
    const isShowDashboard = isAdminOnly || (isPtcUser && isAdminSCA);

    const isAdmin = ["Admin", "Service Center Admin"].includes(this.state.userInfo?.user?.role);

    const selectedBrandId = utilityFunctions.getCookie("brandId");
    if (this.state.isLoading) {
      return <LoadingPage />;
    }
    return (
      <Router>
        <Switch>
          {/* Use conditionals to control whether user has access to paths */}

          {isShowDashboard && (
            <ProtectedRoute
              exact
              path="/"
              test={this?.state?.userInfo}
              goToOnFailure={"/login"}
              render={() => (
                <PageWithNavbar
                  logout={this.handleLogout}
                  userInfo={this?.state?.userInfo}
                  isSuper={this.state.isSuper}
                  protectedFetch={this.protectedFetch}
                  handleSelectedBrandID={this.handleSelectedBrandID}
                >
                  <Dashboard protectedFetch={this.protectedFetch} userInfo={this?.state?.userInfo} />
                </PageWithNavbar>
              )}
            />
          )}

          {/* Warranties is home for not super and not home for super */}
          <ProtectedRoute
            exact
            path={"/tickets"}
            test={this?.state?.userInfo}
            goToOnFailure={"/login"}
            render={() => (
              <PageWithNavbar
                userInfo={this?.state?.userInfo}
                logout={this.handleLogout}
                isSuper={this.state.isSuper}
                protectedFetch={this.protectedFetch}
                handleSelectedBrandID={this.handleSelectedBrandID}
              >
                <Warranties selectedbrandID={selectedBrandId} protectedFetch={this.protectedFetch} />
              </PageWithNavbar>
            )}
          />
          <ProtectedRoute
            exact
            path={"/"}
            test={this?.state?.userInfo}
            goToOnFailure={"/login"}
            render={() => (
              <PageWithNavbar
                userInfo={this?.state?.userInfo}
                logout={this.handleLogout}
                isSuper={this.state.isSuper}
                protectedFetch={this.protectedFetch}
                handleSelectedBrandID={this.handleSelectedBrandID}
              >
                <Warranties selectedbrandID={selectedBrandId} protectedFetch={this.protectedFetch} />
              </PageWithNavbar>
            )}
          />
          <ProtectedRoute
            exact
            path="/inventory"
            test={this?.state?.userInfo}
            goToOnFailure={"/login"}
            render={() => (
              <PageWithNavbar
                userInfo={this?.state?.userInfo}
                logout={this.handleLogout}
                isSuper={this.state.isSuper}
                protectedFetch={this.protectedFetch}
                handleSelectedBrandID={this.handleSelectedBrandID}
              >
                <Inventory selectedbrandID={selectedBrandId} protectedFetch={this.protectedFetch} userInfo={this.state.userInfo} />
              </PageWithNavbar>
            )}
          />
          <ProtectedRoute
            exact
            path="/reports"
            test={this?.state?.userInfo}
            goToOnFailure={"/login"}
            render={() => (
              <PageWithNavbar
                userInfo={this?.state?.userInfo}
                logout={this.handleLogout}
                isSuper={this.state.isSuper}
                protectedFetch={this.protectedFetch}
                handleSelectedBrandID={this.handleSelectedBrandID}
              >
                <Reports selectedbrandID={selectedBrandId} protectedFetch={this.protectedFetch} />
              </PageWithNavbar>
            )}
          />
          <ProtectedRoute
            exact
            path="/warranty/:id"
            test={this?.state?.userInfo}
            goToOnFailure={"/login"}
            render={(props) => (
              <PageWithNavbar
                userInfo={this?.state?.userInfo}
                logout={this.handleLogout}
                isSuper={this.state.isSuper}
                protectedFetch={this.protectedFetch}
                handleSelectedBrandID={this.handleSelectedBrandID}
              >
                <SingleWarranty
                  protectedFetch={this.protectedFetch}
                  isSuper={this.state.isSuper}
                  selectedbrandID={selectedBrandId}
                  userInfo={this.state.userInfo}
                  // employees={this.state.employees}
                  {...props}
                />
              </PageWithNavbar>
            )}
          />
          <ProtectedRoute
            exact
            path="/customer-detail/:id"
            test={this?.state?.userInfo}
            goToOnFailure={"/login"}
            render={(props) => (
              <PageWithNavbar
                userInfo={this?.state?.userInfo}
                logout={this.handleLogout}
                isSuper={this.state.isSuper}
                protectedFetch={this.protectedFetch}
                handleSelectedBrandID={this.handleSelectedBrandID}
              >
                <CustomerDetail 
                protectedFetch={this.protectedFetch} 
                isSuper={this.state.isSuper} 
                selectedbrandID={selectedBrandId}  
                userInfo={this?.state?.userInfo} {...props} />
              </PageWithNavbar>
            )}
          />
          <ProtectedRoute
            exact
            path="/customer-list"
            test={this?.state?.userInfo}
            goToOnFailure={"/login"}
            render={(props) => (
              <PageWithNavbar
                userInfo={this?.state?.userInfo}
                logout={this.handleLogout}
                isSuper={this.state.isSuper}
                protectedFetch={this.protectedFetch}
                handleSelectedBrandID={this.handleSelectedBrandID}
              >
                <CustomerList
                  protectedFetch={this.protectedFetch}
                  isSuper={this.state.isSuper}
                  userInfo={this?.state?.userInfo}
                  selectedbrandID={this.state.selectedbrandID}
                  {...props}
                />
              </PageWithNavbar>
            )}
          />
          <ProtectedRoute
            exact
            path="/service-center/:id"
            test={this?.state?.userInfo}
            goToOnFailure={"/login"}
            render={(props) => (
              <PageWithNavbar
                userInfo={this?.state?.userInfo}
                logout={this.handleLogout}
                isSuper={this.state.isSuper}
                protectedFetch={this.protectedFetch}
                handleSelectedBrandID={this.handleSelectedBrandID}
              >
                <ServiceCenterDetail protectedFetch={this.protectedFetch} isSuper={this.state.isSuper} selectedbrandID={selectedBrandId} {...props} />
              </PageWithNavbar>
            )}
          />
          {isAdmin ? (
            <ProtectedRoute
              exact
              path="/sellers"
              test={this?.state?.userInfo}
              goToOnFailure={"/login"}
              render={() => (
                <PageWithNavbar
                  userInfo={this?.state?.userInfo}
                  logout={this.handleLogout}
                  isSuper={this.state.isSuper}
                  protectedFetch={this.protectedFetch}
                  handleSelectedBrandID={this.handleSelectedBrandID}
                >
                  <Sellers protectedFetch={this.protectedFetch} />
                </PageWithNavbar>
              )}
            />
          ) : null}
          <ProtectedRoute
            exact
            path="/service-centers"
            test={this?.state?.userInfo}
            goToOnFailure={"/login"}
            render={(props) => (
              <PageWithNavbar
                userInfo={this?.state?.userInfo}
                logout={this.handleLogout}
                isSuper={this.state.isSuper}
                protectedFetch={this.protectedFetch}
                handleSelectedBrandID={this.handleSelectedBrandID}
              >
                <ServiceCenterList
                  protectedFetch={this.protectedFetch}
                  userInfo={this?.state?.userInfo}
                  isSuper={this.state.isSuper}
                  selectedbrandID={selectedBrandId}
                  {...props}
                />
              </PageWithNavbar>
            )}
          />

          {isAdmin ? (
            <ProtectedRoute
              exact
              path="/manage-staff"
              test={this?.state?.userInfo}
              goToOnFailure={"/login"}
              render={() => (
                <PageWithNavbar
                  userInfo={this?.state?.userInfo}
                  logout={this.handleLogout}
                  isSuper={this.state.isSuper}
                  protectedFetch={this.protectedFetch}
                  handleSelectedBrandID={this.handleSelectedBrandID}
                >
                  <ManageStaff protectedFetch={this.protectedFetch} />
                </PageWithNavbar>
              )}
            />
          ) : null}
          {isAdmin ? (
            <ProtectedRoute
              exact
              path="/device"
              test={this?.state?.userInfo}
              goToOnFailure={"/login"}
              render={() => (
                <PageWithNavbar
                  userInfo={this?.state?.userInfo}
                  logout={this.handleLogout}
                  isSuper={this.state.isSuper}
                  protectedFetch={this.protectedFetch}
                  handleSelectedBrandID={this.handleSelectedBrandID}
                >
                  <Device protectedFetch={this.protectedFetch} selectedbrandID={this.state.selectedbrandID} />
                </PageWithNavbar>
              )}
            />
          ) : null}
          {isAdmin ? (
            <ProtectedRoute
              exact
              path="/device-detail/:id"
              test={this?.state?.userInfo}
              goToOnFailure={"/login"}
              render={(props) => (
                <PageWithNavbar
                  userInfo={this?.state?.userInfo}
                  logout={this.handleLogout}
                  isSuper={this.state.isSuper}
                  protectedFetch={this.protectedFetch}
                  handleSelectedBrandID={this.handleSelectedBrandID}
                >
                  <DeviceDetail protectedFetch={this.protectedFetch} selectedbrandID={this.state.selectedbrandID} {...props} />
                </PageWithNavbar>
              )}
            />
          ) : null}

          <ProtectedRoute
            exact
            path="/staff-detail/:id"
            test={this?.state?.userInfo}
            goToOnFailure={"/login"}
            render={(props) => (
              <PageWithNavbar
                userInfo={this?.state?.userInfo}
                logout={this.handleLogout}
                isSuper={this.state.isSuper}
                protectedFetch={this.protectedFetch}
                handleSelectedBrandID={this.handleSelectedBrandID}
              >
                <StaffDetail protectedFetch={this.protectedFetch} isSuper={this.state.isSuper} {...props} />
              </PageWithNavbar>
            )}
          />

          <ProtectedRoute
            exact
            path="/staff-new"
            test={this?.state?.userInfo}
            goToOnFailure={"/login"}
            render={(props) => (
              <PageWithNavbar
                userInfo={this?.state?.userInfo}
                logout={this.handleLogout}
                isSuper={this.state.isSuper}
                protectedFetch={this.protectedFetch}
                handleSelectedBrandID={this.handleSelectedBrandID}
              >
                <StaffAdd protectedFetch={this.protectedFetch} isSuper={this.state.isSuper} {...props} />
              </PageWithNavbar>
            )}
          />
          <ProtectedRoute
            exact
            path="/addnewdevice"
            test={this.state.isAuthenticated}
            goToOnFailure={"/needsverification"}
            render={() => (
              <PageWithNavbar
                userInfo={this?.state?.userInfo}
                logout={this.handleLogout}
                isSuper={this.state.isSuper}
                protectedFetch={this.protectedFetch}
                handleSelectedBrandID={this.handleSelectedBrandID}
              >
                <AddNewDevice protectedFetch={this.protectedFetch} />
              </PageWithNavbar>
            )}
          />
          {this.state.isSuper && (
            <ProtectedRoute
              exact
              path="/users"
              test={this?.state?.userInfo}
              goToOnFailure={"/login"}
              render={() => (
                <PageWithNavbar
                  userInfo={this?.state?.userInfo}
                  logout={this.handleLogout}
                  isSuper={this.state.isSuper}
                  protectedFetch={this.protectedFetch}
                  handleSelectedBrandID={this.handleSelectedBrandID}
                >
                  <Users protectedFetch={this.protectedFetch} />
                </PageWithNavbar>
              )}
            />
          )}
          {this.state.isSuper && (
            <ProtectedRoute
              exact
              path="/user/:id"
              test={this?.state?.userInfo}
              goToOnFailure={"/login"}
              render={(props) => (
                <PageWithNavbar
                  userInfo={this?.state?.userInfo}
                  logout={this.handleLogout}
                  isSuper={this.state.isSuper}
                  protectedFetch={this.protectedFetch}
                  handleSelectedBrandID={this.handleSelectedBrandID}
                >
                  <SingleUser protectedFetch={this.protectedFetch} {...props} />
                </PageWithNavbar>
              )}
            />
          )}
          <ProtectedRoute
            exact
            path="/login"
            test={true}
            goToOnFailure={"/notfound"}
            render={(props) => (
              <AuthContainer>
                <Login onLogin={this.userHasAuthenticated} {...props} />
              </AuthContainer>
            )}
          />
          {/* Finally, catch all unmatched routes */}
          <Route path="/error" component={ErrorPage} />
          <Route
            render={() => (
              <PageWithNavbar
                userInfo={this?.state?.userInfo}
                logout={this.handleLogout}
                isSuper={this.state.isSuper}
                protectedFetch={this.protectedFetch}
                handleSelectedBrandID={this.handleSelectedBrandID}
              >
                <NotFound />
              </PageWithNavbar>
            )}
          />
        </Switch>
      </Router>
    );
  }
}

//Show if route shouldn't be available
class ProtectedRoute extends React.Component {
  render() {
    const { ...props } = this.props;
    return <Route {...props} render={() => (this.props?.test ? this.props.render(this.props) : <Redirect to={this.props.goToOnFailure} />)} />;
  }
}
