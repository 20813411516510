import React, { Component } from "react";
import BarChartDevices from "../BarChartDevices";
import PieChartOpenTickets from "../PieChartOpenTickets";
import PieChartWarranty from "../PieChartWarranty";
import PieChartServiceType from "../PieChartServiceType";
import config from "../../../config";
import errorFunctions from "../../../store/errorFunctions";
import LoadingPage from "../../../components/Loading/Loading";
import moment from "moment";
import { IoIosRefreshCircle } from "react-icons/io";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      dashboardData: undefined,
      dataChart3: undefined,
      devices: undefined,
      lastUpdated: new Date(),
      employee: [],
      dateRange: "thisYear",
      startDate: new Date(moment().startOf("year").format("YYYY-MM-DD")),
      endDate: new Date(moment().format("YYYY-MM-DD")),
      isPickDate: true,
    };
  }

  retriveDashboardData(start_date, end_date) {
    console.log("enter retriveDashboardDataw ");
    this.setState({
      loading: true,
      isPickDate: true,
    });

    let dateRange = "";
    const date_started = start_date || new Date(this.state.startDate).toISOString().split("T")[0];
    const date_ended = end_date || new Date(this.state.endDate).toISOString().split("T")[0];
    if (date_started > date_ended) {
      alert("Filtering failed. Please check, start date is newer than end date");
      return;
    }

    if (date_started && date_ended) dateRange = `?date_started=${date_started}&date_ended=${date_ended}`;

    this.props
      .protectedFetch(config.backendServer + "dashboard/" + dateRange, "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        this.setState({
          loading: false,
          lastUpdated: moment(),
          dashboardData: res,

          // deviceId: brandDevice[0]?.id,
        });
        this.retrieveEmployee();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleRangeChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      [id]: value,
    });

    let start_Date = "";
    let end_Date = "";

    switch (value) {
      case "last365":
        start_Date = moment().subtract(365, "days").startOf("day").format("YYYY-MM-DD");
        end_Date = moment().endOf("day").format("YYYY-MM-DD");

        // code block
        break;
      case "lastYear":
        start_Date = moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD");
        end_Date = moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD");
        break;
      case "thisYear":
        start_Date = moment().startOf("year").format("YYYY-MM-DD");
        end_Date = moment().format("YYYY-MM-DD");
        break;
      case "lastMonth":
        start_Date = moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD");
        end_Date = moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD");
        break;
      case "thisMonth":
        start_Date = moment().startOf("month").format("YYYY-MM-DD");
        end_Date = moment().format("YYYY-MM-DD");

        break;
      default:
        start_Date = moment().startOf("year").format("YYYY-MM-DD");
        end_Date = moment().format("YYYY-MM-DD");
      // code block
    }

    if (value !== "custom") {
      this.setState({
        startDate: new Date(start_Date),
        endDate: new Date(end_Date),
      });
      this.retriveDashboardData(start_Date, end_Date);
    }
  };

  handleInputChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      [id]: value,
    });
    console.log(id + " =" + value);
  };

  retrieveEmployee = () => {
    this.props
      .protectedFetch(config.backendServer + "employee/", "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        this.setState({ employee: res });
      })
      .catch(() => {
        console.log("fail");
      });
  };

  componentDidMount() {
    this.retriveDashboardData();
    // this.retriveDashboardData();
    setInterval(() => this.retriveDashboardData(), 3600000);
  }
  handleDatePickerKeyDown(e) {
    console.log(e.key);
    if (e.key === "Enter") this.retriveDashboardData();
    else this.setState({ isPickDate: false });
  }

  render() {
    if (this.state.loading) {
      return <LoadingPage />;
    }
    const dashboardData = this.state.dashboardData;

    return (
      <>
        {dashboardData && (
          <div>
            <div className="d-flex flex-row justify-content-between card p-2">
              <div className="d-flex flex-row">
                <div className="m-2">{`Last update: ${this.state.lastUpdated.format("DD-MMM-YYYY HH:mm:ss ")}`}</div>
                <button title="Refresh" className="btn btn-outline-primary btn-sm" onClick={() => this.retriveDashboardData()}>
                  <IoIosRefreshCircle title="Reload data" style={{ fontSize: "1.5rem" }} />
                </button>
              </div>

              <div className="d-flex flex-row gap-2">
                <div className="m-2">{"Date Range :"}</div>
                <div>
                  <select id="dateRange" className="form-select" onChange={this.handleRangeChange} value={this.state.dateRange}>
                    <option value="last365">Last 365 days</option>
                    <option value="lastYear">Last year</option>
                    <option value="thisYear">This year</option>
                    <option value="lastMonth">Last month</option>
                    <option value="thisMonth">This month</option>
                    <option value="custom">Custom</option>
                  </select>
                </div>
                <div>
                  <DatePicker
                    id="startDate"
                    disabled={this.state.dateRange !== "custom"}
                    className="form-control"
                    selected={new Date(this.state.startDate)}
                    dateFormat="dd/MM/yyyy"
                    onKeyDown={(e) => {
                      this.handleDatePickerKeyDown(e);
                    }}
                    onChange={(val) => {
                      const startDate = val?.toISOString().split("T")[0];
                      const endDate = this.state.endDate.toISOString().split("T")[0];
                      this.setState({
                        startDate: new Date(startDate),
                      });
                      if (this.state.isPickDate) this.retriveDashboardData(startDate, endDate);
                    }}
                  />
                </div>
                <div>
                  <DatePicker
                    id="endDate"
                    disabled={this.state.dateRange !== "custom"}
                    className="form-control"
                    selected={new Date(this.state.endDate)}
                    dateFormat="dd/MM/yyyy"
                    onKeyDown={(e) => {
                      this.handleDatePickerKeyDown(e);
                    }}
                    onChange={(val) => {
                      const endDate = val?.toISOString().split("T")[0];
                      const startDate = this.state.startDate.toISOString().split("T")[0];

                      this.setState({
                        endDate: new Date(endDate),
                      });
                      if (this.state.isPickDate) this.retriveDashboardData(startDate, endDate);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-1">
              <div className="col-md-12">
                <div className="card d-flex align-items-center justify-content-center">
                  <h6 className="mt-3">Device Received</h6>
                  <div
                    style={{
                      width: "80%",
                      padding: "30px",
                    }}
                  >
                    <BarChartDevices data={dashboardData.number_of_device_chart} />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="card d-flex align-items-center justify-content-center">
                  <h6 className="mt-3">Service Types</h6>
                  <div style={{ width: "60%" }}>
                    <PieChartServiceType data={dashboardData.type_of_service_ticket_chart} />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card d-flex align-items-center justify-content-center ">
                  <h6 className="mt-3">Ticket Status</h6>
                  <div style={{ width: "60%" }}>
                    {/* {this.state.dataChart3 && ( */}
                    <PieChartOpenTickets type="pie" data={dashboardData.open_closed_ticket_chart} />
                    {/* )} */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="card d-flex align-items-center justify-content-center">
                  <h6 className="mt-3">Warranty Status</h6>
                  <div style={{ width: "60%" }}>
                    <PieChartWarranty data={dashboardData.warranty_ticket_chart} />
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        )}
      </>
    );
  }
}
