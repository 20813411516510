import React, { Component } from "react";
import "./Devices.css";
// import "../common.css";
import config from "../../../config";
// import { FaPlus } from "react-icons/fa";
// import CreditCard from "../../../img/icons/credit_card.png";
// import StoreFront from "../../../img/icons/storefront.png";
// import LocationOn from "../../../img/icons/location_on.svg";
// import DateRange from "../../../img/icons/date_range.svg";
import { MdClose, MdHistory, MdEdit } from "react-icons/md";
import errorFunctions from "../../../store/errorFunctions";
import utilityFunctions from "../../../store/utilityFunctions";
import AddNewDevice from "./AddNewDevice/AddNewDevice";

import moment from "moment";
// import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import SetWarrantyDetails from "./SetWarrantyDetails/SetWarrantyDetails";

import Modal from "react-modal";
Modal.setAppElement("body");

export default class Devices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      devices: [],
      // customerDevices: [],
      userDevice: null,
      noProfile: false,
      loading: true,
      showDeleteModal: false,
      showIosPopup: false,
      addWarrantyModal: false,
      showApproveModal: false,
      redirectToProfile: false,
      openModalHistory: false,
      openAddDevice: false,
      currentHistoryTicket: [],
      currentTicketNumber: "",
      currentDevice: null,
      userInfo: localStorage.getItem("userInfo") || utilityFunctions.getCookie("userInfo"),
    };
  }

  downloadProofOfPurchase = (id, serial_number) => {
    // Can't download files on iOS. If iOS, set pop-up saying 'please use computer instead'
    const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (iOS) {
      this.setState({ showIosPopup: true });
      return; // Exit function now
    }

    this.setState({ loading: true });
    this.props
      .protectedFetch(config.backendServer + `user_device/${id}/download_proof/`, "GET", null, (res) => {
        return res; // Do everything in the 'then'
      })
      .then((res) => {
        if (res.ok === true || res.status === 200) {
          res.blob().then((res) => {
            const blob = res;
            blob.name = `Purchase_${serial_number}`;
            utilityFunctions.downloadFileAutomatically(blob, res.type);
          });
        } else {
          alert("error " + res.status);
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log("fail", err);
        this.setState({ loading: false });
      });
  };

  getResponseHeadersAsJSON(headers) {
    const contentDisposition = headers.get("content-disposition");
    const headersObj = {};

    if (contentDisposition) {
      const match = contentDisposition.match(/filename=(?<filename>.+)/);
      if (match && match.groups && match.groups.filename) {
        headersObj["filename"] = match.groups.filename;
      }
    }

    return headersObj;
  }
  handleDelete = () => {
    const deviceToDelete = this.state.devices[this.state.showDeleteModal].id; // Storing array id in showDeleteModal
    this.setState({ loading: true, showDeleteModal: false });
    this.props
      .protectedFetch(config.backendServer + "user_device/" + deviceToDelete + "/delete/", "DELETE", null, errorFunctions.checkResponseSent)
      .then(() => {
        // Mimic a refresh of a page with the below
        this.componentDidMount();
      })
      .catch((err) => {
        console.log("fail", err);
        this.setState({ loading: false });
      });
  };
  handleAddWarranty = () => {
    const deviceId = this.state.devices[this.state.addWarrantyModal].id; // Storing array id
    this.setState({ loading: true, addWarrantyModal: false });
    this.props
      .protectedFetch(config.backendServer + "addnewwarranty", { deviceId }, errorFunctions.checkResponseSent)
      .then(() => {
        // Mimic a refresh of a page with the below
        this.componentDidMount();
      })
      .catch((err) => {
        console.log("fail", err);
        this.setState({ loading: false });
      });
  };
  handleHistory = (data) => {
    this.setState({
      openModalHistory: !this.state.openModalHistory,
      currentHistoryTicket: data?.tickets,
      // currentTicketNumber: data?.ticket_number,
      // currentTicketType: data?.ticket_type,
    });
  };

  handleEdit = (data) => {
    this.setState({
      openAddDevice: true,
      currentDevice: data,
      // currentTicketNumber: data?.ticket_number,
      // currentTicketType: data?.ticket_type,
    });
  };

  render() {
    // var isMobile = Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") > -1;

    const that = this;
    // const validStatus = ["Service Completed", "Refund Processed (Completed)", "Replacement Sent (Completed)", "Not Approved"];
    const closedStatus = [
      "Did Not Proceed",
      "Not Approved",
      "Service Completed",
      "Completed",
      "Refund Processed",
      "Replacement Sent",
      "Repair complete, goods sent out",
    ];

    return (
      <div>
        <div className={"pageContent clearfix"}>
          <div>
            <button className="btn btn-outline-primary btn-sm" onClick={() => this.setState({ openAddDevice: true, currentDevice: null })}>
              Add Device
            </button>
          </div>

          <div className={"pageContent clearfix"}>
            {this.props.data
              ?.sort((a, b) => (a.device.name - b.device.name ? 1 : -1))
              .map(function (item, i) {
                const lastTicket = item?.tickets[item.tickets.length - 1];
                const lastTicketStatus = lastTicket?.status;
                const isAddReport = !lastTicketStatus || closedStatus.includes(lastTicketStatus);
                return (
                  <div key={i} className="card mb-3 bordered">
                    <div className="card-header text-bg-secondary">
                      <div className="row">
                        <div className="col"> {item.device.name}</div>
                        <div className="col-2 text-end">
                          {item.tickets.length > 0 && (
                            <button className="btn btn-secondary btn-sm" onClick={() => that.handleHistory(item)}>
                              <MdHistory />
                            </button>
                          )}

                          <button className="btn btn-secondary btn-sm" onClick={() => that.handleEdit(item)}>
                            <MdEdit />
                          </button>
                        </div>
                      </div>{" "}
                    </div>

                    <div className="card-body row no-margin mt-3">
                      <div className="col-lg-2 col-md-12">
                        <img
                          src={item.device.image || "https://www.dreametech.com/cdn/shop/products/20220902103443_590x.png?v=1662089670"}
                          width={"100%"}
                          alt=""
                        />
                      </div>
                      <div className="col">
                        <div className="device-card-line">
                          <span> Series Number : {item.serial_number}</span>
                        </div>
                        <div className="device-card-line">
                          <span> Purchase Date : {moment(item.date_of_purchase).format("DD-MM-YYYY")}</span>
                        </div>

                        {item.proof_of_purchase && (
                          <a
                            className="k-btn k-btn-outline-primary k-btn-sm mt-2"
                            href={item.proof_of_purchase_photo}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Proof of Purchase
                          </a>
                        )}
                        <div className="device-card-line"></div>
                      </div>
                      <div className="col">
                        <div className="device-card-line">
                          <span> Seller Details : {item.seller_details}</span>
                        </div>
                        <div className="device-card-line">
                          <span> Store Location : {item.store_location}</span>
                        </div>

                        {isAddReport && (
                          <button
                            className={"k-btn k-btn-outline-primary k-btn-sm mt-2"}
                            onClick={(e) => {
                              e.stopPropagation();
                              that.setState({
                                showApproveModal: true,
                                userDevice: item.id,
                              });
                            }}
                          >
                            Log Fault
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <Modal
          isOpen={this.state.showDeleteModal !== false}
          onRequestClose={() => this.setState({ showDeleteModal: false })}
          contentLabel={"Delete Device"}
          className={"confirm-modal"}
        >
          <button onClick={() => this.setState({ showDeleteModal: false })} className={"confirm-modal-close"}>
            <MdClose />
          </button>
          <div className={"confirm-main-text"}>
            Are you sure you want to delete{" "}
            {this.state.devices[this.state.showDeleteModal] ? this.state.devices[this.state.showDeleteModal].name : "this device"}?
          </div>
          <div className={"confirm-sub-text"}>All associated warranty requests will also be deleted.</div>
          <div className={"approveDeclineSet"}>
            <button onClick={this.handleDelete} className={"decline"}>
              Yes
            </button>
            <button className={"neutral"} onClick={() => this.setState({ showDeleteModal: false })}>
              No
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showIosPopup}
          onRequestClose={() => this.setState({ showIosPopup: false })}
          contentLabel={"Download not available on mobile."}
          className={"info-modal"}
        >
          <button onClick={() => this.setState({ showIosPopup: false })} className={"info-modal-close"}>
            <MdClose />
          </button>
          <div className={"info-main-text"}>Sorry but download is unavailable on mobile.</div>
          <div className={"info-sub-text"}>If you wish to see your proof of purchase document, please download on a computer.</div>
        </Modal>
        <Modal
          isOpen={this.state.addWarrantyModal !== false}
          onRequestClose={() => this.setState({ addWarrantyModal: false })}
          contentLabel={"Add Warranty Request"}
          className={"confirm-modal confirm-modal-big"}
          style={{
            content: { top: "calc(50% - 100px)", bottom: "calc(50% - 100px)" },
          }}
        >
          <button onClick={() => this.setState({ addWarrantyModal: false })} className={"confirm-modal-close"}>
            <MdClose />
          </button>
          <div className={"confirm-main-text"}>
            Are you sure you want to submit a warranty request for{" "}
            {this.state.devices[this.state.addWarrantyModal] ? this.state.devices[this.state.addWarrantyModal].name : "this device"}?
          </div>
          <div className={"confirm-sub-text"}>
            After submitting this request, a member of our warranty team will contact you via email on service@roborock.com.au to discuss your
            warranty needs.
          </div>
          <div className={"approveDeclineSet"}>
            <button onClick={this.handleAddWarranty} className={"approve"}>
              Yes
            </button>
            <button className={"neutral"} onClick={() => this.setState({ addWarrantyModal: false })}>
              No
            </button>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.openModalHistory}
          onRequestClose={() => this.setState({ openModalHistory: false })}
          contentLabel={"Add Warranty Request"}
          className={"input-modal"}
        >
          <div className="card">
            <button onClick={() => this.setState({ openModalHistory: false })} className={"confirm-modal-close"}>
              <MdClose />
            </button>
            <div className="card-header">
              <h5>Device history</h5>
            </div>
            <div className="card-body" style={{ maxHeight: "500px", overflow: "auto" }}>
              <div className="container">
                {this.state.currentHistoryTicket.length === 0 ? (
                  <tbody>
                    <tr>
                      <td colSpan="4" className="text-center">
                        No History found.
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <table className="table">
                    <thead style={{ backgroundColor: "#A94442", color: "white" }}>
                      <tr>
                        <th>Ticket Number</th>
                        <th>Status</th>
                        <th>Created</th>
                        <th>Ticket Type</th>
                      </tr>
                    </thead>
                    <tbody style={{ fontSize: "0.9rem", textAlign: "left" }}>
                      {this.state.currentHistoryTicket &&
                        this.state.currentHistoryTicket.length > 0 &&
                        this.state.currentHistoryTicket.map((ticket) => (
                          <tr key={ticket.id}>
                            <td>{ticket.ticket_number}</td>
                            <td style={{ wordWrap: "break-word" }}>{ticket.status}</td>
                            <td>{moment(ticket.created).format("DD MMM YYYY HH:mm")}</td>
                            <td>{ticket.ticket_type.charAt(0).toUpperCase() + ticket.ticket_type.slice(1)}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.openAddDevice}
          onRequestClose={() => this.setState({ openAddDevice: false })}
          contentLabel={"Add New Device."}
          // style={customStyles}
          className={"input-modal w-50 card "}
        >
          <div className="card-header">{this.state.currentDevice ? "Edit Device" : "Add New Device"}</div>
          <button onClick={() => this.setState({ openAddDevice: false })} className={"info-modal-close"}>
            <MdClose />
          </button>
          <AddNewDevice
            protectedFetch={this.props.protectedFetch}
            selectedbrandID={this.props.selectedbrandID}
            customerId={this.props.customerId}
            currentDevice={this.state.currentDevice}
            handleCloseModal={() => this.setState({ openAddDevice: false })}
            handleRefresh={() => this.props.handleRefresh()}
            userInfo={this.state.userInfo}
          />
        </Modal>
        {/* Modal form for approving warranty form */}
        <SetWarrantyDetails
          userDevice={this.state.userDevice}
          customerId={this.props.customerId}
          selectedbrandID={this.props.selectedbrandID}
          protectedFetch={this.props.protectedFetch}
          showModal={this.state.showApproveModal}
          hideModal={() => this.setState({ showApproveModal: false })}
          retrieveDevicesNew={this.retrieveDevicesNew}
          retrieveCustomerDevices={() => this.props.retrieveCustomerDevices()}
        />
      </div>
    );
  }
}
