import React, { Component } from "react";
import "./AddNewDevice.css";
import "../../../common.css";
import config from "../../../../config";
// import { Redirect } from "react-router-dom";
// import HalfPageImageRight from "../../../../pageContainers/halfPageImageRight/halfPageImageRight";
import LoadingPage from "../../../../components/Loading/Loading";
import errorFunctions from "../../../../store/errorFunctions";
import axios from "axios";
// import utilityFunctions from "../../../../store/utilityFunctions";
import { icons } from "../../../../img/icons";
import Select from "react-select";
export default class AddNewDevice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proofOfPurchase: this.props.currentDevice?.proofOfPurchase || true,
      redirect: false,
      isLoading: false,
      id: null,
      userDeviceId: this.props.currentDevice?.id,
      deviceName: "",
      deviceId: this.props.currentDevice?.device.id,
      devices: [],
      deviceOptions: [],
      dateOfPurchase: this.props.currentDevice?.date_of_purchase,
      serialNumber: this.props.currentDevice?.serial_number,
      textProofOfPurchase: this.props.currentDevice?.proof_of_purchase_text,
      proofOfPurchasePhoto: this.props.currentDevice?.proof_of_purchase_photo,
      fileProofOfPurchaseName:
        this.props.currentDevice?.proof_of_purchase_photo?.split("/")[this.props.currentDevice?.proof_of_purchase_photo?.split("/").length - 1],
      device: "",
      allowUpload: true,
      sellerDetails: [],
      sellerDetailInput: "",
      sellerDetail: this.props.currentDevice?.seller_details,
      sellerOptions: [],
      storeLocations: [],
      storeLocation: this.props.currentDevice?.store_location || "",
      storeOptions: [],
    };
  }
  componentDidMount() {
    this.retriveDevices();
    if (this.props.computedMatch) {
      this.setState({
        id: this.props.computedMatch.params.id,
        isLoading: true,
      });
    }
    this.retrieveSeller();
    if (this.props.currentDevice) this.retrieveStore(this.props.currentDevice?.seller_details);
  }
  retrieveSeller() {
    this.props.protectedFetch(config.backendServer + "sellers/", "GET", null, errorFunctions.checkResponseThenJson).then((res) => {
      const sellerOptions = res?.map((val) => {

        return { label: val.name, value: val.id };

      });
      this.setState({
        isLoading: false,
        sellerDetails: res,
        sellerOptions,
      });
      // this.retrieveStore(res[0].name);
    });
  }

  retrieveStore(seller) {

    const url = config.backendServer + "seller/" + seller + "/store_locations/?country=" + config.country;
    this.props.protectedFetch(encodeURI(url), "GET", null, errorFunctions.checkResponseThenJson).then((res) => {
      const storeOptions = res?.length
        ? res?.map((val) => {
            return { label: val.name, value: val.name };
          })
        : [];

      this.setState({
        isLoading: false,
        storeLocations: res,
        storeOptions,
        // storeLocation: "-",
      });
    });
  }

  retriveDevices() {
    this.props.protectedFetch(config.backendServer + "devices/", "GET", null, errorFunctions.checkResponseThenJson).then((res) => {
      const brandDevice = res?.map((dev) => {
        return {
          id: dev.id,
          name: dev.name,
          brandId: dev.brand.id,
        };
      });
      const deviceOptions = brandDevice?.map((val) => {
        return { label: val.name, value: val.id };
      });
      this.setState({
        isLoading: false,
        devices: brandDevice,
        deviceId: this.props.currentDevice ? this.props.currentDevice?.device.id : null,
        brandId: brandDevice[0].brandId,
        deviceOptions,
      });
    });
  }
  retrieveDetails(id) {
    // Rather than make a new endpoint, just use devices endpoint and filter here
    this.props
      .protectedFetch(config.backendServer + "getdevices", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        const filteredDevices = res.filter((item) => {
          return item.id === id;
        });
        this.setState({
          isLoading: false,
          deviceName: filteredDevices[0].name,
          dateOfPurchase: filteredDevices[0].date,
          sellerDetails: filteredDevices[0].seller_details,
          storeLocation: filteredDevices[0].store_location,
          serialNumber: filteredDevices[0].serial_number,
          proofOfPurchase: Boolean(filteredDevices[0].proof_of_purchase),
          textProofOfPurchase: filteredDevices[0].proof_of_purchase_text,
        });
      })
      .catch(() => {
        console.log("fail");
        this.setState({ isLoading: false });
      });
  }
  handleRadioChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value === "true",
    });
  };
  handleInputChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      [id]: value,
    });
  };
  handleSellerChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      [id]: value,
    });
    this.retrieveStore(value);
  };
  handleFileUpload = (e) => {
    // TODO - verify file upload to make sure it's good
    const file = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
    this.setState({ fileProofOfPurchaseName: file.name, proofOfPurchasePhoto: file });
    if (!allowedTypes.includes(file.type)) {
      alert("Only JPG, JPEG, PNG, and PDF files are allowed.");
      this.setState({ allowUpload: false });
    } else {
      this.setState({ allowUpload: true });
    }
  };
  handleSubmit = (e) => {
    // this.setState({ isLoading: true });
    e.preventDefault();

    const token = JSON.parse(this.props.userInfo).access_token;

    let options = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    let formData = new FormData();
    formData.append("device", this.state.deviceId);
    formData.append("date_of_purchase", this.state.dateOfPurchase);
    formData.append("seller_details", this.state.sellerDetail === "Other" ? this.state.sellerDetailInput : this.state.sellerDetail);
    formData.append("store_location", this.state.storeLocation);
    formData.append("serial_number", this.state.serialNumber);
    formData.append("proof_of_purchase", this.state.proofOfPurchase);
    formData.append("customer", this.props.customerId);
    formData.append("brand", this.state.brandId);

    if (this.state.proofOfPurchase) {
      const existProfOfPurchase =
        this.props.currentDevice?.proof_of_purchase_photo?.split("/")[this.props.currentDevice?.proof_of_purchase_photo?.split("/").length - 1];
      if (this.state.allowUpload === true && existProfOfPurchase !== this.state.fileProofOfPurchaseName) {
        formData.append("proof_of_purchase_photo", document.getElementById("uploadProofOfPurchase").files[0]);
      }
    } else {
      formData.append("proof_text", this.state.textProofOfPurchase);
    }
    if (this.state.userDeviceId) {
      formData.append("id", this.state.userDeviceId);
      // If id in state, then existing device
      axios
        .patch(config.backendServer + "customers/" + this.props.customerId + "/device/" + this.state.userDeviceId + "/", formData, options)
        .then((response) => {
          // Update state or perform other actions
          console.log(response);
          this.setState({ isLoading: false });
          this.props.handleRefresh();
          this.props.handleCloseModal();
        })
        .catch((error) => {
          // Handle the error or show an error message
          alert(error?.response?.data?.non_field_errors?.[0] || error?.response?.data?.serial_number?.[0] || "Something went wrong.. try later");
          this.setState({ isLoading: false });
          throw new Error(error);
        });
    } else {
      // If no id in state, then new device
      axios
        .post(config.backendServer + "customers/" + this.props.customerId + "/devices/", formData, options)
        .then((response) => {
          // Update state or perform other actions
          console.log(response);
          this.setState({ isLoading: false });
          this.props.handleRefresh();
          this.props.handleCloseModal();
        })
        .catch((error) => {
          // Handle the error or show an error message
          alert(error?.response?.data?.non_field_errors?.[0] || error?.response?.data?.serial_number?.[0] || "Something went wrong.. try later");
          this.setState({ isLoading: false });
          throw new Error(error);
        });
    }
    this.props.handleRefresh();
  };

  isEditDisabled = () => {
    return (
      this.state.deviceId === this.props.currentDevice?.device.id &&
      this.state.sellerDetail === this.props.currentDevice?.seller_details &&
      this.state.storeLocation === this.props.currentDevice?.store_location &&
      this.state.dateOfPurchase === this.props.currentDevice?.date_of_purchase &&
      this.state.serialNumber === this.props.currentDevice?.serial_number &&
      this.state.dateOfPurchase === this.props.currentDevice?.date_of_purchase &&
      this.state.dateOfPurchase === this.props.currentDevice?.date_of_purchase &&
      this.state.textProofOfPurchase === this.props.currentDevice?.proof_of_purchase_text &&
      this.state.fileProofOfPurchaseName ===
        this.props.currentDevice?.proof_of_purchase_photo?.split("/")[this.props.currentDevice?.proof_of_purchase_photo?.split("/").length - 1]
    );
  };

  render() {
    if (this.state.isLoading) {
      return <LoadingPage small={true} />;
    }

    const deviceValue = this.state.deviceOptions?.find((x) => x.value === this.state.deviceId);
    const sellerValue = this.state.sellerOptions?.find((x) => x.value === this.state.sellerDetail);
    const storeValue = this.state.storeOptions?.find((x) => x.value === this.state.storeLocation);

    return (
      <form onSubmit={this.handleSubmit} className="form-input ">
        <div className={"py-3 overflow-auto card-body"} style={{ height: "70vh" }}>
          <div key={0}>
            <label>{"Device Name*"}</label>
            <Select
              id={"deviceId"}
              data-testid="assign-to-select"
              value={deviceValue}
              isSearchable={true}
              options={this.state.deviceOptions}
              onChange={(obj) => {
                this.setState({ deviceId: obj.value });
              }}
            />
          </div>
          <div key={1} className="form-input">
            <label>{"Date of Purchase*"}</label>
            <input
              type={"date"}
              required={true}
              onChange={this.handleInputChange}
              id={"dateOfPurchase"}
              className="form-control datepicker-css w-50"
              value={this.state.dateOfPurchase}
            />
            <div className={"add-new-device-input-info"}>{"Date of Purchase"}</div>
          </div>
          <div key={2} className="form-input">
            <label>{"Seller details*"}</label>
            <Select
              id={"sellerDetail"}
              data-testid="assign-to-select"
              value={sellerValue}
              isSearchable={true}
              options={this.state.sellerOptions}
              onChange={(obj) => {
                this.setState({ sellerDetail: obj.label });
                this.retrieveStore(obj.value);
              }}
            />

            <div className={"add-new-device-input-info"}>{"Where did you purchase this from? (include seller details)"}</div>
          </div>
          <div key={3} className="form-input" style={{ display: this.state.storeLocations?.length > 0 ? "block" : "none" }}>
            <label>{"Store Location"}</label>
            <Select
              id={"storeLocation"}
              data-testid="assign-to-select"
              value={storeValue}
              isSearchable={true}
              options={this.state.storeOptions}
              onChange={(obj) => {
                this.setState({ storeLocation: obj.value });
              }}
            />
            <div className={"add-new-device-input-info"}>{"Store location where device is bought"}</div>
          </div>
          <div key={4} className="form-input" style={{ display: this.state.sellerDetail === "Other" ? "block" : "none" }}>
            <label>{"Input Seller*"}</label>
            <input
              className="form-control"
              type={"text"}
              required={this.state.sellerDetail === "Other"}
              onChange={this.handleInputChange}
              id={"sellerDetailInput"}
              value={this.state.sellerDetailInput}
            />
            <div className={"add-new-device-input-info"}>{"Input manually seller where device is bought"}</div>
          </div>
          <div key={5} className="form-input">
            <label>{"Serial Number*"}</label>
            <input
              className="form-control"
              type={"text"}
              required={true}
              onChange={this.handleInputChange}
              id={"serialNumber"}
              value={this.state.serialNumber}
            />
            <div className={"add-new-device-input-info"}>
              {
                "To locate the Serial Number of your Dreame product, simply check the sticker on the device itself, typically found under the barcode. The Serial Number is often labelled as 'S/N' and can be identified easily."
              }
            </div>
          </div>

          <div key={6} className={"form-field add-new-device-radio-field"}>
            <label className="required">
              Proof of Purchase
              <span> :</span>
            </label>
            <input
              type="radio"
              name={"proofOfPurchase"}
              value={true}
              onChange={this.handleRadioChange}
              required
              defaultChecked={this.state.proofOfPurchase === true}
            />{" "}
            Yes
            <input
              type="radio"
              name={"proofOfPurchase"}
              value={false}
              onChange={this.handleRadioChange}
              required
              defaultChecked={this.state.proofOfPurchase === false}
            />{" "}
            No
          </div>
          {/* I'm a little lazy, hardcoding these custom variables in */}
          {this.state.proofOfPurchase && (
            <div className={"form-field add-new-device-proof-field"}>
              <div>Please upload your proof of purchase</div>
              <label htmlFor="uploadProofOfPurchase" className="custom-file-upload">
                <img src={icons.Upload} alt="" /> Choose File
              </label>
              <span>{`  ${this.state.fileProofOfPurchaseName ? this.state.fileProofOfPurchaseName : "No file choosen"}`}</span>
              <input
                accept="image/jpeg, image/png, application/pdf"
                type={"file"}
                id={"uploadProofOfPurchase"}
                // className="custom-file-upload"
                // required={this.state.proofOfPurchase}
                onChange={this.handleFileUpload}
              />
            </div>
          )}

          {!this.state.proofOfPurchase && (
            <div className={"form-field add-new-device-proof-field"}>
              <label>Please provide details to prove where the purchase was from</label>
              <textarea required={true} onChange={this.handleInputChange} id={"textProofOfPurchase"} value={this.state.textProofOfPurchase} />
            </div>
          )}
          <div className={"add-new-device-proof-info form-input"}>
            All warranty claims must have proof of purchase in order for us to verify the warranty. We will not accept submissions with no proof.
          </div>
        </div>
        <div className="row card-footer">
          <button className={`k-btn k-btn-primary col m-1`} disabled={this.isEditDisabled()} type="submit">
            Submit
          </button>
          <button className={"btn btn-outline-secondary col m-1"} onClick={() => this.props.handleCloseModal()}>
            Cancel
          </button>
        </div>
      </form>
    );
  }
}
